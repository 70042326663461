export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    return
  }

  const supabase = useSupabaseClient<Supabase>()
  const auth = useAuth()
  const data = useData()
  const returnPath = `/event/${to.params.id}`
  const resourceId = useShortId(to.params.id)

  if (typeof auth.session === 'undefined') {
    return await new Promise<ReturnType<typeof navigateTo>>((resolve) => {
      watchOnce(
        () => auth.session,
        async (value) => {
          if (value === null && to.path !== returnPath) {
            resolve(navigateTo(returnPath))

            return
          }
          else {
            const existingScope = data.scopes.get(resourceId)

            if (
              existingScope
              && (existingScope.scope === 'editor' || existingScope.scope === 'owner')
            ) {
              resolve()

              return
            }
            else if (value) {
              const { data: scopes } = await supabase
                .from('scopes')
                .select()
                .eq('resource_id', resourceId)
                .eq('scoped_user_id', value.user.id)
                .in('scope', ['editor', 'owner'])

              if (scopes === null || scopes.length === 0) {
                resolve(navigateTo(returnPath))

                return
              }
              else {
                data.cacheMany('scopes', scopes)

                resolve()
              }
            }
          }
        },
        { deep: true },
      )
    })
  }
  else if (auth.session === null && to.path !== returnPath) {
    return navigateTo(returnPath)
  }
  else if (auth.session) {
    const existingScope = data.scopes.get(resourceId)

    if (existingScope?.scope !== 'editor' && existingScope?.scope !== 'owner') {
      const { data: scopes } = await supabase
        .from('scopes')
        .select()
        .eq('resource_id', resourceId)
        .eq('scoped_user_id', auth.session.user.id)
        .in('scope', ['editor', 'owner'])

      if (scopes === null || scopes.length === 0) {
        return navigateTo(returnPath)
      }
      else {
        data.cacheMany('scopes', scopes)
      }
    }
  }
})
